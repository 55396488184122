<template>
  <div class="notice main">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="form.label" name="first">
        <div class="notice-list" v-loading="loading" element-loading-text="拼命加载中">
          <ul>
            <li v-for="(item,index) in noticeList" :key="index" @click="goToEditor(item)">
              <el-link type="primary">{{item.title}}</el-link><span class="time">{{item.creatDate|formatTime}}</span>
            </li>
          </ul>
          <el-empty v-if="noticeList&&noticeList==0" description="暂无数据"></el-empty>
        </div>
        <div class="block">
          <el-pagination background v-if="total>0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="page.pageNum" :page-size="page.pageSize" layout="prev, pager, next, jumper,total"
            :total="total">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="right-breadcrumb">
      <span class="desc">您当前的位置：</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{form.label}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import { queryTitleList, blurryQuery } from '@/api/index'
export default {
  data () {
    return {
      activeName: 'first',
      loading: false,
      page: {
        pageNum: 1,
        pageSize: 15
      },
      total: 0,
      noticeList: [],
      form: {
        label: '',

      }
    };
  },
  created () {
    this.setTitle(this.$route.params.id)
    console.log(this.$route)
    this.getList(this.$route.name == 'search' ? 'search' : 'list')
  },
  watch: {
    $route (e) {
      console.log(e)
      this.setTitle(e.params.id)
      if (e.name == 'search') {
        this.getList('search')
      } else {
        this.getList('list')
      }

    }
  },
  methods: {
    goToEditor (row) {
      this.$router.push('/editor/1?id=' + row.did + '&titleType=' + row.titleType)
    },
    getList (name) {
      this.loading = true
      if (name == 'list') {
        const data = {
          titleType: this.form.titleType,
          ...this.page
        }
        queryTitleList(data).then(res => {
          this.noticeList = res.data.list
          this.total = res.data.total
          this.loading = false
        })
      } else {
        const data = {
          keywords: this.keywords,
          ...this.page
        }
        blurryQuery(data).then(res => {
          this.noticeList = res.data.list
          this.total = res.data.total
          this.loading = false
        })
      }
    },
    setTitle (type) {
      if (this.$route.name == 'search') {
        this.form.label = '文章搜索'
        this.keywords = this.$route.params.keywords
      }
      else {
        if (type == 1) {
          this.form.label = '通知公告'
          this.form.titleType = 'TZ'
        } else if (type == 2) {
          this.form.label = '工作动态'
          this.form.titleType = 'DT'
        } else if (type == 3) {
          this.form.label = '政策法规'
          this.form.titleType = 'ZC'
        } else if (type == 4) {
          this.form.label = '国家重点研发计划'
          this.form.titleType = 'SP'
        }
      }
    },
    handleClick () { },
    handleSizeChange (size) {
      this.page.pageSize = size
      this.getList(this.$route.name == 'search' ? 'search' : 'list')
    },
    handleCurrentChange (num) {
      this.page.pageNum = num
      this.getList(this.$route.name == 'search' ? 'search' : 'list')
    }
  },
  components: {}
};
</script>

<style scoped lang="scss">
.notice {
  position: relative;
  /deep/ .el-tabs__item {
    font-size: 16px;
  }
  h1 {
    text-align: center;
  }
  .notice-list {
     min-height: 300px;
    ul {
      list-style-type: circle;
    }
    li::before {
      content: "";
      width: 5px;
      height: 5px;
      border: 1px dotted #a1a1a1;
      /* Rotate */
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      /* Rotate Origin */
      -webkit-transform-origin: 0 100%;
      -moz-transform-origin: 0 100%;
      -ms-transform-origin: 0 100%;
      -o-transform-origin: 0 100%;
      transform-origin: 0 100%;
      position: absolute;
      left: 5px;
      top: 15px;
    }
    li {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      line-height: 30px;
      padding-left: 40px;
      position: relative;
      /deep/ .el-link {
        font-size: 16px;
      }
      /deep/ .el-link.el-link--primary {
        color: #000;
      }
    }
  }
  .block {
    margin-top: 20px;
    text-align: center;
  }
  /deep/ .el-tabs__content {
    padding-bottom: 40px;
  }
}
.right-breadcrumb {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 10px;
  .desc {
    font-size: 14px;
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 768px) {
  // nav
  .el-tabs__header {
    padding: 0 10px;
  }
  .el-tabs__content {
    padding-bottom: 0px !important;
  }
  .scientific-list {
    padding: 0 10px;
  }
  .scientific .el-tabs__item {
    font-size: 14px;
  }
  .scientific .scientific-list li {
    padding-left: 15px !important;
    font-size: 14px !important;
  }
  .scientific .scientific-list li .el-link {
    font-size: 14px !important;
  }
  .scientific .scientific-list ul {
    margin-top: 20px !important;
  }
  .scientific .scientific-list li::before {
    left: 5px !important;
  }
  .notice .notice-list li,.notice .notice-list li .el-link{
    font-size: 14px !important;
  }
  .notice-list li .el-link{
    width: 75%;
  }
  .notice .notice-list li{
    padding-left: 30px !important;
    padding-right: 10px !important;
  }
  .notice .notice-list li::before{
    left: 15px !important;
  }
}
</style>
